import Vue from 'vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/es';
import numeral from 'numeral';
import numeralLocale from 'numeral/locales/es';





window.numeral = numeral;
numeral.locale('es', numeralLocale);

window.Vue = Vue;
Vue.use(ElementUI, { locale });


window.axios = require('axios');
require('promise.prototype.finally').shim();
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['x-csrf-token'] = token.content;
} else {
    console.error('CSRF token not found: https://adonisjs.com/docs/4.1/csrf');
}
